import React from "react";
import Icon from "../Icon";

export default (props) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path
      d="M16 2.883c4.272 0 4.778.016 6.465.093 1.56.071 2.407.332 2.971.551.747.29 1.28.637 1.84 1.197s.907 1.093 1.197 1.84c.219.564.48 1.41.55 2.97.078 1.688.094 2.194.094 6.466s-.016 4.778-.093 6.465c-.071 1.56-.332 2.408-.551 2.971a4.957 4.957 0 01-1.197 1.84c-.56.56-1.093.907-1.84 1.197-.564.219-1.41.48-2.97.55-1.688.078-2.194.094-6.466.094s-4.778-.016-6.465-.093c-1.56-.071-2.408-.332-2.971-.551a4.957 4.957 0 01-1.84-1.197 4.957 4.957 0 01-1.197-1.84c-.219-.563-.48-1.41-.55-2.97-.078-1.688-.094-2.194-.094-6.466s.016-4.778.093-6.465c.071-1.56.332-2.407.551-2.971.29-.747.637-1.28 1.197-1.84a4.958 4.958 0 011.84-1.197c.563-.219 1.41-.48 2.97-.55 1.688-.078 2.194-.094 6.466-.094M16 0c-4.345 0-4.89.018-6.597.096C7.7.174 6.537.444 5.52.84a7.843 7.843 0 00-2.834 1.845A7.843 7.843 0 00.84 5.52C.444 6.537.174 7.7.096 9.403.018 11.11 0 11.655 0 16c0 4.345.018 4.89.096 6.597.078 1.703.348 2.866.744 3.884a7.843 7.843 0 001.845 2.834A7.843 7.843 0 005.52 31.16c1.018.396 2.181.666 3.884.744C11.11 31.982 11.655 32 16 32c4.345 0 4.89-.018 6.597-.096 1.703-.078 2.866-.348 3.884-.744a7.843 7.843 0 002.834-1.845 7.843 7.843 0 001.845-2.834c.396-1.018.666-2.181.744-3.884C31.982 20.89 32 20.345 32 16c0-4.345-.018-4.89-.096-6.597-.078-1.703-.348-2.866-.744-3.884a7.843 7.843 0 00-1.845-2.834A7.843 7.843 0 0026.48.84C25.463.444 24.3.174 22.597.096 20.89.018 20.345 0 16 0zm0 7.784a8.216 8.216 0 100 16.432 8.216 8.216 0 000-16.432zm0 13.55a5.333 5.333 0 110-10.667 5.333 5.333 0 010 10.666zM26.46 7.458a1.92 1.92 0 11-3.84 0 1.92 1.92 0 013.84 0z"
      stroke="none"
    />
  </Icon>
);
