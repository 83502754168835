import { TranslationContext } from "../contexts";
import { useContext } from "react";

export default function useTranslation() {
  const { i18n, moment } = useContext(TranslationContext);
  return {
    t: (...args) => i18n.t(...args),
    language: i18n.language,
    i18n,
    moment,
  };
}
