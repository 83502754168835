import "./IconButton.scss";
import cx from "classnames";

import React, { forwardRef } from "react";
import useAsyncCallback from "hooks/useAsyncCallback";

export default forwardRef(function IconButton(
  {
    size = "md",
    variant = "contained",
    color = "text",
    onClick,
    className,
    disabled,
    children,
    ...props
  },
  ref
) {
  const [{ isPending, showPending }, asyncOnClick] = useAsyncCallback(onClick, [
    onClick,
  ]);
  const prefix = variant === "text" ? "icon-link" : "icon-btn";
  return (
    <button
      ref={ref}
      className={cx(
        `${prefix}`,
        `${prefix}--${size}`,
        `${prefix}--${color}`,
        className
      )}
      onClick={asyncOnClick}
      disabled={isPending || disabled}
      {...props}
    >
      {showPending ? <div className="loader loader-sm" /> : children}
    </button>
  );
});
