import { chooseRandom } from "./arrays";

const AVATAR_COLORS = [
  "red",
  "orange",
  "yellow",
  "green",
  "cyan",
  "blue",
  "purple",
  "pink",
];

const hash = (keys) => (s) =>
  (parseInt(s.substring(s.length - 13), 36) % 31) % keys;

const randomColor = (seed) =>
  typeof seed === "string"
    ? AVATAR_COLORS[hash(AVATAR_COLORS.length)(seed)]
    : chooseRandom(AVATAR_COLORS);

export { AVATAR_COLORS, randomColor };
