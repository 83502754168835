import React from "react";
import Icon from "../Icon";

export default (props) => (
  <Icon viewBox="0 0 64 64" {...props}>
    <title>Ikeetmee on Twitter</title>
    <path
      d="M18.196 58.013c-.202-.01-.823-.055-1.382-.105C11.13 57.42 5.742 55.673.784 52.71c-.443-.267-.796-.49-.784-.505 0-.01.283.004.61.036 5.827.55 11.906-.96 16.87-4.21.848-.55 1.83-1.26 1.83-1.32 0-.02-.134-.04-.298-.04-.428 0-1.22-.09-1.917-.22-4.014-.76-7.477-3.38-9.298-7.06-.367-.74-.763-1.73-.714-1.77.015-.01.312.02.665.06.99.13 1.81.16 2.65.1 1.082-.07 2.6-.35 2.497-.44-.025-.01-.2-.07-.386-.1-1.64-.34-3.8-1.41-5.26-2.6-2.85-2.3-4.66-5.88-4.8-9.47l-.04-.83.64.3c1.32.62 2.64 1.02 3.95 1.18.65.08 1.35.15 1.35.12 0 0-.3-.24-.67-.51-1.6-1.22-2.98-2.91-3.88-4.77-.87-1.82-1.26-3.59-1.26-5.68 0-1.61.2-2.87.69-4.28.21-.62.99-2.32 1.06-2.312.01 0 .29.33.62.71 1.26 1.53 3.69 3.84 5.5 5.24 5.54 4.31 12.33 7 19.31 7.67 1.17.11 1.72.138 1.71.06 0-.03-.07-.382-.14-.79-.18-.98-.19-3.14-.03-4.11.23-1.41.58-2.51 1.16-3.72.67-1.38 1.36-2.38 2.4-3.45 1.99-2.06 4.48-3.366 7.33-3.85.87-.15 2.54-.206 3.42-.114 3.04.32 5.78 1.604 7.95 3.714.44.424.34.413 1.57.12 1.54-.38 3.26-.98 4.75-1.67.72-.33 1.92-.96 2.26-1.18l.14-.094-.15.41c-.96 2.66-2.73 4.935-5.08 6.505-.28.19-.51.36-.5.37.04.037 1.3-.17 2.23-.36 1.55-.323 3.21-.83 4.61-1.41.332-.14.61-.244.62-.236.02.023-.857 1.207-1.367 1.844-1.31 1.64-2.81 3.11-4.545 4.448l-.597.46v1.65c0 2.65-.21 4.793-.72 7.29-.72 3.52-1.844 6.746-3.494 10.01-3.23 6.37-8.065 11.625-13.93 15.12-5.73 3.42-12.41 5.17-19.48 5.11-.73 0-1.49-.012-1.69-.03z"
      stroke="none"
    />
  </Icon>
);
