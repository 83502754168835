import { navigate } from "gatsby";

import React from "react";
import useColorModeContext from "hooks/useColorModeContext";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import { DropDown, IconButton } from "components/UI";
import LocalizedLink from "components/LocalizedLink";
import MarketingInfo from "components/MarketingInfo";

export default function Footer() {
  const { t, language } = useTranslation();
  const [colorMode, setColorMode] = useColorModeContext();

  return (
    <div className="p-4 text-center space-y-4">
      <div className="flex flex-wrap wrapping-box--4 justify-center">
        <a
          className="link link--text capitalize"
          href="https://app.ikeetmee.nl"
        >{t`navigation:app`}</a>
        <LocalizedLink
          className="link link--text capitalize"
          to="/privacy"
        >{t`navigation:privacy`}</LocalizedLink>
        <LocalizedLink
          className="link link--text capitalize"
          to="/terms"
        >{t`navigation:terms`}</LocalizedLink>
        <a
          className="link link--text capitalize"
          href="https://forms.gle/s4tx29kQFwwZGkqz7"
          target="_blank"
          rel="noreferrer noopener"
        >{t`navigation:feedback`}</a>
      </div>
      <MarketingInfo />
      <div className="flex items-center justify-center space-x-4">
        <DropDown
          required
          size="sm"
          className="max-w-48"
          value={language}
          options={[
            { value: "nl", label: "Nederlands" },
            { value: "en", label: "English" },
          ]}
          onChange={(nextLang) =>
            nextLang !== language &&
            navigate(
              nextLang === "nl"
                ? window.location.pathname.replace(`/${language}/`, "/")
                : `/${nextLang}${window.location.pathname.replace(
                    `/${language}/`,
                    "/"
                  )}`
            )
          }
        />
        <IconButton
          color="card"
          size="sm"
          onClick={() =>
            setColorMode((colorMode) =>
              colorMode === "light" ? "dark" : "light"
            )
          }
        >
          <Icons.Eye state={colorMode === "light" ? "open" : null} />
        </IconButton>
      </div>
      <div className="flex items-center justify-center space-x-4">
        <a
          href="mailto:wouterraateland@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icons.Email size={2} />
        </a>
        <a
          href="https://facebook.com/ikeetmee"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icons.Facebook size={2} />
        </a>
        <a
          href="https://instagram.com/ikeetmee.nl"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icons.Instagram size={2} />
        </a>
      </div>
      <p className="text-caption">&copy;{new Date().getFullYear()} Ikeetmee</p>
    </div>
  );
}
