import "./WrappingBox.scss";
import cx from "classnames";

import React from "react";

export default function WrappingBox({ spacing = 2, className, ...props }) {
  return (
    <div
      className={cx(`flex flex-wrap wrapping-box--${spacing}`, className)}
      {...props}
    />
  );
}
