import { useEffect } from "react";

export default function usePageScroll(onScroll) {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleScroll = () =>
        onScroll(document.documentElement.scrollTop || 0);
      handleScroll();

      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [onScroll]);
}
