import "./Tabs.scss";

import * as _ from "utils";
import cx from "classnames";

import React, { useRef } from "react";

import LocalizedLink from "components/LocalizedLink";

const Container = ({ className, bg, ...props }) => (
  <div
    className={cx(
      "tabs__container space-x-8",
      `tabs__container--${bg}`,
      className
    )}
    {...props}
  />
);
const Tab = ({ getProps = _.getCurrentDataProps, ...props }) => {
  const tabRef = useRef(null);

  return (
    <LocalizedLink
      ref={tabRef}
      getProps={(props) => {
        const res = getProps(props);
        if (res["data-partially-current"]) {
          const tab = tabRef.current;
          if (tab) {
            const container = tab.offsetParent;
            if (container) {
              container.scrollTo({
                left:
                  tab.offsetLeft +
                  tab.offsetWidth / 2 -
                  container.offsetWidth / 2,
                behavior: "smooth",
              });
            }
          }
        }
        return res;
      }}
      className="tabs__tab"
      {...props}
    />
  );
};

const Tabs = () => null;
Tabs.Container = Container;
Tabs.Tab = Tab;

export default Tabs;
