import * as _ from "utils";
import cx from "classnames";

import React, { useMemo } from "react";

import * as Icons from "components/Icons";

const getPlaceholder = (user) =>
  user?.name?.charAt(0)?.toUpperCase() ||
  user?.email?.charAt(0)?.toUpperCase() ||
  null;
const getColorSeed = (user) =>
  user?.userIdentifiers?.length > 0
    ? user.userIdentifiers[0]
    : getPlaceholder(user);
const getColor = (colorSeed) => (colorSeed ? _.randomColor(colorSeed) : "card");

export default function Avatar({ user, size, className, ...props }) {
  const color = useMemo(() => getColor(getColorSeed(user)), [user]);
  const placeholder = getPlaceholder(user);

  return user ? (
    <div
      className={cx(
        "flex items-center justify-center flex-shrink-0 rounded-full bg-center bg-cover",
        `w-${size * 4} h-${size * 4}`,
        `bg-${color}`,
        className
      )}
      style={{ backgroundImage: `url(${user.avatar})` }}
      {...props}
    >
      {!user.avatar &&
        (placeholder ? (
          <p style={{ fontSize: `${size * 0.5}rem` }} className="font-bold">
            {placeholder}
          </p>
        ) : (
          <Icons.Avatar className="rounded-full opacity-50" size={size} />
        ))}
    </div>
  ) : null;
}
