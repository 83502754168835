import React from "react";
import useTranslation from "hooks/useTranslation";

import * as Icons from "components/Icons";
import { NavBar } from "components/UI";
import LocalizedLink from "components/LocalizedLink";

export default function PageNavigation() {
  const { t } = useTranslation();

  return (
    <NavBar>
      <div className="flex items-center justify-space-between px-4 font-bold">
        <LocalizedLink to="/" className="text-text space-x-2">
          <Icons.Ikeetmee color="secondary" strokeWidth={8} />
          <span className="hidden sm:inline">Ikeetmee</span>
        </LocalizedLink>
        <div className="flex items-center space-x-8">
          <LocalizedLink
            className="link link--caption space-x-2"
            to="/feedback"
            onClick={(event) => {
              event.preventDefault();
              window.open("https://forms.gle/s4tx29kQFwwZGkqz7", "_blank");
            }}
          >
            <Icons.Comment />
            <span>{t`navigation:feedback`}</span>
          </LocalizedLink>
          <a
            className="btn btn--primary btn--sm"
            href="https://app.ikeetmee.nl"
          >
            {t`navigation:toApp`}
          </a>
        </div>
      </div>
    </NavBar>
  );
}
