import React from "react";
import useTranslation from "hooks/useTranslation";

import { Link } from "gatsby";

const defaultLocale = "nl";
const supportedLocales = ["nl", "en"];

export default function LocalizedLink({ to, ...props }) {
  const { language } = useTranslation();

  return (
    <Link
      to={
        supportedLocales.includes(to.split("/").filter(Boolean)[0])
          ? to
          : language === defaultLocale
          ? to
          : `/${language}${to}`
      }
      {...props}
    />
  );
}
