import React from "react";
import Icon from "../Icon";

export default (props) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path
      d="M24.145 12.968l2.677-4.626a.924.924 0 00-.378-1.236.926.926 0 00-1.208.32l-2.734 4.713a16.673 16.673 0 00-13.004 0L6.764 7.426a.935.935 0 00-1.266-.291.926.926 0 00-.32 1.207l2.677 4.626A15.68 15.68 0 000 25.36h32a15.68 15.68 0 00-7.855-12.392zM8.727 21.36a1.819 1.819 0 11.002-3.637 1.819 1.819 0 01-.002 3.637zm14.546 0a1.819 1.819 0 11.001-3.637 1.819 1.819 0 01-.001 3.637z"
      stroke="none"
    />
  </Icon>
);
