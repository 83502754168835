import React from "react";
import Icon from "../Icon";

export default props => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path
      d="M13.5 14.5v10m5-10v10M4 6h24m-4 5H8v17h16V11zm-5-5V4h-6v2"
      fill="none"
    />
  </Icon>
);
