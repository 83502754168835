import cx from "classnames";
import React from "react";

export default function Tag({ size, color = "text", className, ...props }) {
  return (
    <p
      className={cx(
        "rounded-xl px-2",
        `accent-${color} text-${color}`,
        className
      )}
      {...props}
    />
  );
}
