import "./NavBar.scss";

import React, { useCallback, useRef } from "react";
import useWindowScroll from "hooks/useWindowScroll";

export default function NavBar({ children }) {
  const outerRef = useRef(null);
  const innerRef = useRef(null);

  const onScroll = useCallback((scrollTop) => {
    const outer = outerRef.current;
    const inner = innerRef.current;
    if (outer && inner) {
      outer.classList.toggle("navbar--down", scrollTop > 0);
      outer.classList.toggle("bg-card", scrollTop > 0);
      inner.classList.toggle("py-4", scrollTop <= 0);
      inner.classList.toggle("py-2", scrollTop > 0);
    }
  }, []);
  useWindowScroll(onScroll);

  return (
    <div ref={outerRef} className="navbar">
      <div
        ref={innerRef}
        className="flex-grow max-w-4xl mx-auto transition-all duration-100"
      >
        {children}
      </div>
    </div>
  );
}
