import React from "react";
import Icon from "../Icon";

export default (props) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path
      d="M28.371 24.92a17.386 17.386 0 01-1.719 3.092c-.904 1.289-1.644 2.181-2.215 2.677-.884.813-1.831 1.23-2.846 1.253-.728 0-1.607-.207-2.63-.628-1.025-.418-1.968-.625-2.83-.625-.904 0-1.873.207-2.91.625-1.04.42-1.876.64-2.516.662-.973.041-1.942-.387-2.91-1.287-.618-.54-1.391-1.463-2.317-2.772-.993-1.397-1.81-3.018-2.45-4.865C2.344 21.056 2 19.123 2 17.252c0-2.142.463-3.99 1.39-5.54a8.158 8.158 0 012.913-2.946 7.835 7.835 0 013.938-1.11c.772 0 1.786.238 3.045.708 1.256.471 2.062.71 2.416.71.264 0 1.16-.28 2.679-.836 1.436-.517 2.648-.731 3.64-.647 2.69.217 4.712 1.278 6.056 3.189-2.406 1.457-3.596 3.5-3.572 6.12.021 2.04.762 3.737 2.216 5.086a7.285 7.285 0 002.215 1.452 23.934 23.934 0 01-.565 1.483zM22.201.64c0 1.599-.584 3.092-1.749 4.474-1.405 1.644-3.105 2.593-4.949 2.443a4.979 4.979 0 01-.037-.606c0-1.535.669-3.178 1.856-4.522.592-.68 1.346-1.245 2.26-1.697.912-.444 1.774-.69 2.585-.732.024.214.034.428.034.64z"
      stroke="none"
    />
  </Icon>
);
