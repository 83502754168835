const track = (event, params) =>
  typeof gtag === "function" &&
  typeof window !== undefined &&
  window.gtag("event", event, params);

const setAnalyticsContext = (key, value) =>
  typeof gtag === "function" &&
  typeof window !== undefined &&
  window.gtag("set", key, value);

export { track, setAnalyticsContext };
